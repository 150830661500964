$logoFamily: "Lato";
$titleFmaily: "Lato";
$subTitleFmaily: "Lato";
$txtFamily: "Lato";

// Size
$tiny: 10px;
$small: 11px;
$medium: 13px;
$large: 14px;
$xlarge: 16px;
$xxlarge: 18px;
$xxxlarge: 60px;

h1,
h2,
h3,
h4,
h5 {
  color: $primary;
  text-transform: uppercase;
}

h1 {
  font-family: $titleFmaily;
  font-weight: 800;
  font-size: 60px;
  letter-spacing: 6px;
  line-height: 80px;
}

h2 {
  font-family: $subTitleFmaily;
  font-size: 20px;
  letter-spacing: 2px;
  font-weight: 600;
  text-transform: none;
  line-height: 40px;
}

h3 {
  font-family: $subTitleFmaily;
  font-size: 32px;
  line-height: 64px;
  letter-spacing: 5px;
  font-weight: 600;
  color: $secondary;
}

h4 {
  font-family: $subTitleFmaily;
  letter-spacing: 3px;
}

p,
li,
a,
pre {
  font-family: $txtFamily;
  letter-spacing: 0.5px;
  color: white;
  font-weight: 700;
  font-size: 14px;
}

.form-text {
  color: grey;
}

@media only screen and (max-width: 640px) {
  h1 {
    font-size: 32px;
    line-height: 64px;
  }

  h2 {
    font-size: 24px;
    line-height: 48px;
  }

  h3 {
    font-size: 16px;
    line-height: 32px;
  }
}
